@font-face {
  font-family: "Big Caslon FB";
  src: url("../public/BigCaslonFB-Bold.ttf");
  src: url("../public/BigCaslonFB-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.row.login {
  background-image: url("./Assets/background.png");
  height: 100vh;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  font-family: Poppins !important;
}
/* .qrDownloadSelect .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow {
  width: 100% !important;
  border: 1px solid #676767 !important;
  border-radius: 10px;
  height: 45px;
} */

.qrDownloadSelect .ant-select {
  width: 100% !important;
  height: 45px;
  border: 1px solid #676767 !important;
  border-radius: 10px;
}
.whiteBorder,
.newPasss {
  padding-top: 50px;
}
.whiteBorder input,
span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password {
  border: 1.07px solid #ffffff !important;
  background-color: transparent !important;
  color: #fff;
  font-size: 16px !important;
  border-radius: 8px !important;
  font-family: Poppins !important;
  height: 55px !important;
}
.black {
  border: 5px solid #ceab8e;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #000;
}
span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password input {
  font-family: Poppins !important;
}
.row.uploadImageForm .row {
  align-items: center;
  background: #87562e8a;
  padding: 8px;
  border-radius: 12px;
  margin: 10px 40px;
}
.row.uploadImageForm span.ant-upload-list-item-actions a {
  display: none;
}
.col-sm-12.EditTributeFormImage {
  width: 100%;
  display: block;
  width: 23% !important;
  background: #87562eb3;
  border-radius: 13px;
  margin: 10px;
  padding: 13px;
}
.button.ant-btn:hover {
  color: #fff !important;
}
.container-fluid.loginForm.beforeprof .cennn {
  margin-bottom: 10%;
}
.beforebtns .ant-upload.ant-upload-select {
  width: 100% !important;
}
.col-sm-12.beforebtns
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  border: none !important;
  height: auto !important;
}
.col-sm-12.beforebtns button {
  margin: 16px 0 0 !important;
}
.beforebtns .ant-upload-list.ant-upload-list-picture-card {
  width: 100% !important;
}
.beforeprof .col-sm-12.editBNtn button {
  width: 10% !important;
  height: 70px !important;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0 !important;
}
.ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
  background: #87562e;
  border-color: #87562e !important;
}
.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #87562e !important;
}
.leterCircle {
  background: #87562E !important;
  width: 40px !important;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.leterCircle p{
  margin-bottom: 0;
  color: #fff;
  font-weight: 600;
}
.tributeDescription {
  font-size: 16px;
}
.col-sm-12.EditTributeFormImage img {
  width: 100%;
}
.row.login
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before,
.row.uploadImageForm
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
  /* margin-right: 5px !important; */
}
.row.login svg {
  color: #fff !important;
}
.whiteBorder input::placeholder,
input#basic_password::placeholder,
.col-sm-12.passreset input::placeholder {
  color: #c9c9c9 !important;
}
.col-sm-12.cennn {
  text-align: center;
  color: #fff;
  font-family: Poppins !important;
  font-size: 16px !important;
}
.col-sm-6.cennn {
  text-align: center;
  color: #fff;
  font-family: Poppins !important;
  font-size: 16px !important;
}
.col-sm-12.cennn p {
  cursor: pointer;
}
.col-sm-6.cennn p {
  cursor: pointer;
}
.signUplangDiv .col-sm-6.cennn {
  text-align: left !important;
}
.uploaddVideoPhoto .modal-dialog {
  min-width: 65% !important;
}
.signUplang
  .ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent !important;
}
.signUplang .ant-select-selection-placeholder {
  color: #ffffff !important;
  font-size: 16px;
  font-family: Poppins !important;
  text-align: right  !important;
}
.signUplang .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #ffff !important;
  text-align: right;
}


.signUplang .ant-select-dropdown {
  background: transparent !important;
}

.signUplang .ant-select-item-option {
  background: transparent !important;
  color: #ffffff !important;
}

.signUplang .ant-select-item-option-active {
  background: rgba(255, 255, 255, 0.1) !important; /* Slightly different for active state */
}

.signUplang .ant-select-item-option-selected {
  background: rgba(255, 255, 255, 0.2) !important; /* Slightly different for selected state */
}
.row.login .ant-form-item .ant-form-item-label > label {
  color: #fff !important;
  font-size: 15px;
  font-family: Poppins !important;
}
.row.uploadImageForm .ant-form-item .ant-form-item-label > label,
.row.uploadImageForm label {
  font-family: Poppins !important;
}
label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-in-form-item {
  background: #87562e !important;
  border: 1px solid #87562e;
  box-shadow: none !important;
}
.row.headeerr.mobile .col-sm-12 {
  justify-content: space-between;
}
.row.headeerr.mobile .col-sm-12 .offcannvas {
  padding-left: 15px;
}
.offcanvas.offcanvas-start.show {
  padding: 15px 20px 0;
}
.col-sm-12.lisst.language ul {
  margin-bottom: 0 !important;
}
.row.login button {
  height: 50px;
  border-radius: 8px !important;
  background: #87562e !important;
  border: none !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  box-shadow: none !important;
  width: 100% !important;
  margin: 16px 0 24px;
}
.row.login button:hover {
  transform: scale(1.04);
  transition: all ease-in-out 400ms;
}
.row.login button img {
  margin-left: 10px;
}
.container-fluid.loginForm {
  width: 40%;
}
.col-sm-3.sideebarr {
  display: none;
}
.col-sm-9.sideebarr {
  padding: 0;
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
  background: #f0f0f0;
}
::-webkit-scrollbar {
  display: none;
}
.offcanvas-header {
  padding-bottom: 30px !important;
  align-items: flex-start !important;
  border-bottom: 1px solid #87562e;
}
.col-sm-12.logoImage {
  padding: 0px 30px;
  text-align: center;
}
.container-fluid.main {
  height: 100vh;
}
.col-sm-12.lisst ul {
  list-style-type: none;
  width: 100%;
  padding-left: 0 !important;
}
.noMb {
  margin-bottom: 0 !important;
}
li.sidebarQRSListing {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}
.yesMB {
  margin-top: 15px !important;
}
.col-sm-12.lisst li a {
  color: #676767;
  font-family: Poppins !important;
  text-decoration: none;
  text-transform: capitalize;
  width: 100% !important;
  align-items: center;
  gap: 10px;
  display: flex;
  font-weight: 500;
  padding-left: 30px;
}
li.LanguageDropdown label {
  padding-left: 30px;
  color: #676767 !important;
  font-size: 18px !important;
  font-weight: 500;
  font-family: Poppins !important;
}
.col-sm-12.lisst li .contaacus:hover {
  color: #676767 !important;
  cursor: pointer;
}
.col-sm-12.lisst li {
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}
.active {
  /* padding-left: 0px !important; */
  font-size: 16px !important;
  background: #87562e !important;
  padding: 15px 30px;
  color: #fff !important;
  border-radius: 8px;
}
.active-indicator {
  width: 15px;
  height: 15px;
}
.row.headeerr {
  background: #fff;
  height: 70px;
  align-items: center;
}
/* .row.headeerr .col-sm-4{
  justify-content: center;
  display: flex;
  padding-left: 50px;
}
.row.headeerr span.ant-input-affix-wrapper.ant-input-outlined{
  background: #F5F6FA;
  border-radius: 15px !important;
} */
.row.headeerr .col-sm-12 img.profile {
  border-radius: 50%;
  width: 45px;
  height: 48px;
}
.row.headeerr .col-sm-12 {
  display: flex;
  justify-content: flex-end;
}
.row.noPad .col-sm-3.lsistiny {
  width: 100%;
}
.row.headeerr.mobile .col-sm-12 {
  position: relative;
}
.row.headeerr .col-sm-12 .headerDetaill,
.row.headeerr.mobile .col-sm-12 .offcannvas {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-right: 20px;
}
.row.headeerr.mobile .col-sm-12 .offcannvas {
  padding-right: 0 !important;
}
.row.headeerr .col-sm-12 p {
  margin-bottom: 0;
  font-family: "Nunito Sans" !important;
  font-weight: 700;
  color: #404040;
}
.myTributess {
  margin-top: 30px;
}
.myytributesnon{
  margin-top: 0 !important;
}
.slidessTributes .ant-image .ant-image-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
}
.tributerName {
  font-family: Poppins !important;
  font-weight: 600;
  font-size: 18px;
}
.topAPd {
  padding: 30px 15px;
}
.sidebarQRSListing img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.col-sm-12.qrListSidebar {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
}
.sidebarQRSListing {
  /* background: #87562E !important; */
  padding: 5px;
  text-align: center !important;
  justify-content: start;
  border-radius: 10px;
  padding-left: 25px;
}
.sidebarQRSListing p {
  color: #676767 !important;
}
.sidebarQRSListing {
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  margin-bottom: 10px;
}
.fade.purchaseModal.purchaseModalmainn.modal.show .modal-body {
  max-height: 600px;
  overflow: scroll;
}
.sidebarQRSListing P {
  margin-bottom: 0;
  font-family: Poppins !important;
  font-weight: 600;
  font-size: 16px;
}
.col-sm-12.widthSeventy {
  width: 70%;
}
.UserBioMessage {
  font-family: Poppins !important;
  font-weight: 500;
  font-size: 18px;
}
.row.noMArr.fform.tribute {
  margin: 20px 0 !important;
  border-radius: 20px !important;
}
.row.noMArr.fform {
  padding: 30px 0 0px;
  margin-bottom: 75px !important;
  justify-content: center;
}
span.ant-upload button {
  width: 100%;
  border: none !important;
  border-radius: 8px;
  height: 100%;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 200px;
  border: 1px dashed #d9d9d9 !important;
  border-radius: 8px;
}
.col-sm-12.submitt {
  text-align: right;
}
.row.uploadImageForm {
  padding: 40px 0;
}
.col-sm-6.ddivvs:hover .year {
  background: #fff;
  transition: all ease-in-out 500ms;
}
.col-sm-6.ddivvs:hover .year p {
  color: #000;
  transition: all ease-in-out 500ms;
}
.col-sm-6.ddivvs .col-sm-10 p.read {
  font-family: "Big Caslon FB";
  color: #87562e;
  text-decoration: underline;
  cursor: pointer;
}
.year {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #87562e;
}
.year p {
  margin-bottom: 0;
  font-family: "Big Caslon FB";
  color: #fff;
  font-size: 26px;
}
.col-sm-6.ddivvs h2,
.col-sm-6.ddivvs p,
.col-sm-6.ddivvs div {
  text-transform: capitalize !important;
}
.col-sm-6.ddivvs :hover div {
  color: #fff !important;
  transition: all 300ms ease-in-out;
}
.col-sm-6.ddivvs {
  padding: 20px 12px;
}
p.read {
  margin-top: 10px;
}
.col-sm-6.ddivvs .col-sm-10 {
  margin-top: 10px;
  padding-right: 50px;
}
.col-sm-6.ddivvs .col-sm-2 {
  display: flex;
  justify-content: center;
  padding-right: 0;
}
.container-fluid.modaaals h1 {
  font-family: "Big Caslon FB";
  font-size: 50px;
}
.container-fluid.modaaals h2 {
  font-family: "Big Caslon FB";
  font-size: 30px;
  margin: 15px 0 30px;
}
.container-fluid.modaaals p {
  font-family: Poppins;
  font-size: 16px;
  color: #616161;
  margin-bottom: 0;
}
.detailTimelineModal .modal-dialog {
  max-width: 60% !important;
}
.detailTimelineModal .modal-content {
  border: 10px solid #87562e !important;
}
.container-fluid.modaaals .col-sm-12 {
  padding: 0 40px 40px;
}
.notShow {
  color: #000 !important;
}
.yeeess {
  display: none !important;
}
.col-sm-6.ddivvs .col-sm-10 h2 {
  font-family: "Big Caslon FB";
  font-size: 30px;
}
.col-sm-12.timeDetail p {
  font-family: "Big Caslon FB";
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
}
.fade.EditProfile.modal.show .row.noMArr.fform {
  margin-bottom: 0 !important;
  margin-top: 20px !important;
}
.col-sm-12.DeleteButton {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}
.col-sm-12.DeleteButton button {
  background: #87562e !important;
  color: #fff !important;
  border: none !important;
}
.col-sm-6.ddivvs .col-sm-10 p {
  color: #616161;
  font-family: Poppins;
  font-size: 16px;
}
.col-sm-6.ddivvs:hover {
  background: #87562e;
  border-radius: 10px;
  transition: all ease-in-out 500ms;
  box-shadow: 0px 6px 10px 4px #dcd5cc;
}
.col-sm-6.ddivvs:hover .col-sm-10 p.read,
.col-sm-6.ddivvs:hover .col-sm-10 h2,
.col-sm-6.ddivvs:hover .col-sm-10 p {
  color: #fff !important;
  transition: all ease-in-out 500ms;
}
.row.noMArr.fform label {
  font-family: Poppins !important;
}
.col-sm-12.changePassword {
  margin-top: 30px;
}
.col-sm-12.submitt button,
.formRight button,
.settingBTn button,
.changePassword button,
.col-sm-2 button,
.col-sm-12.DeleteButton button,
.brownBgNewLink {
  padding: 0 40px;
  height: 45px;
  border-radius: 10px;
  font-weight: 500;
  background: #87562e !important;
  border: 1px solid #87562e !important;
  font-family: Poppins !important;
  color: #fff;
}
.col-sm-12.submitt button:hover,
.formRight button:hover,
.settingBTn button:hover,
.changePassword button:hover,
.col-sm-2 button:hover,
.brownBgNewLink:hover {
  color: #fff !important;
  transition: all ease-in-out 300ms;
  transform: scale(1.04);
  box-shadow: none !important;
  border: 1px solid #87562e !important;
}
.row.noQRsListed p {
  font-family: Poppins !important;
  font-weight: 500;
  padding-top: 15px;
}
.col-sm-12.noPad {
  height: 88vh;
  display: flex;
  align-items: center;
}
.addQRBtn {
  position: absolute;
  right: 2%;
  height: auto;
  border-radius: 50%;
  padding: 10px 12px 10px;
  background: #87562e !important;
  border: 1px solid #87562e !important;
  top: 63px;
  color: #fff !important;
}
.swiper-pagination-bullet-active {
  background: #87562e !important;
}
.brownBorder {
  padding-top: 15px;
}
.etsyy {
  padding-bottom: 15px;
}
.brownBorder button {
  border: 1px solid #87562e !important;
  color: #87562e !important;
  font-family: Poppins !important;
  padding: 0 40px;
  height: 45px;
  font-weight: 500;
  border-radius: 10px;
}
.brownBgNewLink a {
  background: transparent !important;
  color: #fff !important;
  text-decoration: none;
  white-space: break-spaces;
}
.col-sm-12.EditTributeFormImage {
  display: block !important;
}
.col-sm-12.EditTributeFormImage p {
  text-transform: capitalize;
  color: #fff !important;
  margin: 0 !important;
  font-weight: 600;
}
.row.mrginNo {
  margin: 0 !important;
}
.row.noMArr {
  margin: 0 !important;
  background-color: #fff;
  box-shadow: 5.71px 5.71px 51.39px 0px #0000000d;
}
.row.noMArr.naaavvigationnn {
  justify-content: center;
  padding-top: 20px !important;
}
.row.noPad {
  padding: 40px 0px;
  border-radius: 10px;
  margin: 0 !important;
}
.row.noPad.qrListingNew {
  padding: 0 0 50px;
  border-radius: 10px;
  margin: 0 !important;
  justify-content: flex-start;
  gap: 0.5%;
}
.copytoclipboard {
  background: #7f7f7f !important;
  border-radius: 50%;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none !important;
}
.shareProfile {
  display: flex;
  gap: 7px;
  padding: 10px 12px 50px;
  justify-content: center;
  flex-wrap: wrap;
}
.col-sm-12.neqwFOrm{
  padding: 10px 20px;
}
.col-sm-4.uploadPIc .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, .col-sm-4.uploadPIc .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select{
  height: 50px !important;
  min-width: 220px;
  max-width: 300px;
}
.col-sm-4.uploadPIc .ant-upload-list-item-container{
  height: 50px !important;
}
.row.neqwFOrm{
  margin: 0 !important;
}
.col-sm-12.submitt.neqwFOrm{
  padding-right: 25px !important;
}

.col-sm-6.fullWidth
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.col-sm-6.fullWidth
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 300px !important ;
}
.col-sm-12.imageLimit {
  text-align: center;
  font-family: Poppins !important;
  font-weight: 500;
}
.shareProfiles {
  text-align: center;
}
.shareProfiles p {
  font-family: Poppins !important;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 30px;
}
.row.noPad .col-sm-3.lsistiny {
  box-shadow: 5.71px 5.71px 51.39px 0px #0000000d;
  background-color: #fff;
  margin-top: 60px !important;
  padding: 10px 30px;
  border-radius: 15px;
  width: 24%;
  margin: 10px 4px;
  cursor: pointer;
}
.dasshboard p {
  font-family: Poppins;
  font-weight: 600;
  font-size: 30px;
}
.container-fluid.dasshboard {
  padding: 30px;
}
li.LanguageDropdown
  .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow {
  width: 100%;
  padding-left: 20px;
  font-family: Poppins;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px !important;
}
li.LanguageDropdown .ant-select-selector {
  color: #676767 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}
.profieImagwe {
  width: 205px !important;
  height: 205px;
  object-fit: contain;
  padding: 0 !important;
  border-radius: 50%;
  border: 5px solid #fff !important;
  background: #fff;
}
.profieImagwe img {
  width: 195px;
  height: 195px;
  border-radius: 50%;
}

.col-sm-12.profileImageess {
  width: 70%;
}
.col-sm-9.prooofDEtttail {
  /* width: 300px; */
  display: flex;
  padding: 0;
  justify-content: space-between;
}
.col-sm-12.profileImageess .row.nooMarrgin .col-sm-3 {
  padding: 0;
  width: 210px;
}
.col-sm-12.profileImageess .row.nooMarrgin {
  position: relative;
  margin-top: -80px;
  margin-right: 0 !important;
  margin-left: 0 !important;
  align-items: flex-end;
}
.asd img {
  width: 20px;
}
.editandDelete{
  display: flex;
  gap: 10px;
  position: absolute;
  right: 0;
  top: 0;
}
.editandDelete button{
  background: #87562e !important;
  border: none !important;
  border-radius: 50%;
  height: 40px;

  padding: 0;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editandDelete svg{
  color: #fff !important;
  font-size: 18px !important;
}
.row.inTribute {
  margin: 0 !important;
}
.row.inTribute.tributePage{
  display: flex;
}
.editBTn {
  background: #87562e !important;
  border: none !important;
  border-radius: 50%;
  height: 40px;
  position: absolute;
  right: 0;
  padding: 0;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formRight p {
  font-family: "Big Caslon FB" !important;
  font-size: 28px;
  font-weight: 500;
}
.deadPersonName {
  font-family: "Big Caslon FB" !important;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 10px;
}
.deadPersonDate {
  font-family: "Big Caslon FB" !important;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.coverPhoto {
  width: 100%;
  max-height: 400px;
  border-radius: 15px;
  object-fit: cover;
}
.container-fluid.dasshboard.QR {
  padding: 30px 30px 0 !important;
}
.container-fluid.qrr {
  padding: 0 30px;
}
.nunmber {
  font-size: 18px !important;
  font-weight: 500;
  margin-top: 20px;
  padding-left: 20px;
}
.bbold {
  font-weight: 600;
  padding-left: 20px;
  font-size: 40px !important;
}
.col-sm-3.boxxx {
  margin-top: 30px;
  color: #202224 !important;
  font-family: Poppins !important;
  background-color: #fff;
  border-radius: 12px;
  margin-right: 30px !important;
}
.col-sm-3.boxxx:hover {
  background: #87562e !important;
  color: #fff !important;
  transition: all ease-in-out 400ms;
}
.container-fluid.qrfilter span.ant-input-affix-wrapper.ant-input-outlined {
  height: 40px;
  border-radius: 8px;
  background: #f6f6f6;
  border: 0.56px solid #d5d5d5;
  font-family: Poppins !important;
}
.row.uploadImageForm textarea {
  width: 100%;
  border-radius: 8px;
  border: 0.56px solid #d5d5d5;
  font-family: Poppins !important;
}
.row.noMArr.fform .ant-picker.ant-picker-outlined,
.datee .ant-picker.ant-picker-outlined,
.nipus input,
.videoLink input,
.imageDescription input {
  height: 44px;
  width: 100%;
  border-radius: 8px;
  border: 0.56px solid #d5d5d5;
  font-family: Poppins !important;
}
.rangee .ant-picker.ant-picker-range.ant-picker-outlined {
  width: 50%;
  height: 44px;
  border-radius: 8px;
  border: 0.56px solid #d5d5d5;
  font-family: Poppins !important;
}
.formRight {
  text-align: right;
  padding-bottom: 20px;
}
.uploadHeading p {
  font-family: Poppins !important;
  font-size: 24px;
  font-weight: 500;
}
.subdetail {
  font-size: 20px !important;
}
.cancelBtn {
  background: #d1cece !important;
  margin-right: 15px;
  border-radius: 10px;
  padding: 10px 20px;
  height: auto;
  color: #87562e !important;
  font-weight: 500;
  border: none !important;
  font-family: Poppins !important;
}
.col-sm-12.packageeUpdation p {
  font-family: Poppins !important;
  font-size: 20px;
  text-align: center;
  padding-top: 40px;
}
.col-sm-12.packageeUpdation span {
  background: #d9d9d9;
  padding: 10px 20px;
  font-size: 22px;
  margin-left: 20px;
  border-radius: 10px;
}
.brownbg {
  background: #87562e !important;
  margin-right: 15px;
  border-radius: 10px;
  padding: 10px 20px;
  height: auto;
  color: #fff !important;
  font-weight: 500;
  border: none !important;
  font-family: Poppins !important;
}
.col-sm-12.alignedRigth {
  text-align: right;
}
.container-fluid.qrfilter
  .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow {
  height: 40px;
  border-radius: 8px;
  background: #f6f6f6;
  border: 0.56px solid #d5d5d5 !important;
  width: 100%;
  font-family: Poppins !important;
}
.container-fluid.qrfilter .ant-select-selector {
  border: none !important;
  border-radius: 8px !important;
}
.container-fluid.qrfilter input {
  font-family: Poppins !important;
}
.container-fluid.qrfilter .ant-select .ant-select-arrow {
  color: #87562e !important;
}
.container-fluid.qrfilter button {
  background: #87562e !important;
  height: 40px;
  border-radius: 8px;
  width: 100% !important;
  font-family: Poppins !important;
  font-weight: 400;
}
.row.noPad .col-sm-3 img {
  width: 100%;
}
.QRRadio {
  width: 300px;
}
.col-sm-6.ddivvs div.notShow {
  text-transform: none !important;
}
.qrdetail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  align-items: flex-start;
  gap: 5px;
}
.qrdetail p {
  margin-bottom: 0;
  font-family: Poppins !important;
  font-weight: 500;
  font-size: 20px !important;
}
.qrdetail svg {
  font-size: 20px;
}
.eddit:hover {
  color: #87562e;
  cursor: pointer;
  transition: all ease-in-out 300ms;
}
.col-sm-12.qrimage img {
  height: 130px;
  margin: 30px 0;
}
.col-sm-12.qrimage {
  text-align: center;
}
.row.centers .col-sm-2 {
  width: auto !important;
  display: flex;
  cursor: pointer;
}
.row.centers .col-sm-2 .active {
  font-size: 12px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row.centers .col-sm-2 .active .yeeess {
  display: block !important;
  margin-bottom: 5px;
}
.row.centers .col-sm-2 .active .nooo {
  display: none !important;
}
.row.centers a {
  color: #000;
  font-size: 12px !important;
  font-family: Poppins !important;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 0px;
  min-width: 100px;
}
.row.centers .col-sm-2 a:hover {
  background: #87562e;
  transition: all ease-in-out 300ms;
  color: #fff !important;
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row.faq {
  background: none !important;
  box-shadow: none !important;
}
.row.centers .col-sm-2 a:hover .yeeess {
  display: block !important;
  margin-bottom: 5px;
}
.row.centers .col-sm-2 a:hover .nooo {
  display: none !important;
}
.accordion-button:not(.collapsed),
.accordion-body {
  color: #fff !important;
  background: #87562e !important;
}
.accordion-item:first-of-type,
.accordion-item:last-of-type,
.accordion-header .accordion-button {
  border-radius: 0 !important;
}
.accordion-item {
  margin: 20px 0;
  /* border: 1px solid #676767 !important; */
  border-radius: 0 !important;
}
.container-fluid.sidebarQRR {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("./Assets/close.png") !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.accordion-button::after {
  width: 40px !important;
  height: 40px !important;
  background-image: url("./Assets/expand.png") !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.accordion-body,
.accordion-header button {
  display: flex !important;
  align-items: flex-start;
  gap: 30px;
  flex-direction: row;
  font-family: "Times New Roman", Sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  color: #161616;
  padding: 30px 30px 30px 30px !important;
}
.navLinkks {
  background: #87562e !important;
  color: #fff !important;
  transition: all ease-in-out 300ms;
}
.navLinkks .yeeess {
  display: flex !important;
}
.navLinkks .nooo {
  display: none !important;
}
.row.mrginNo .col-sm-3 .fullImage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullImage img {
  width: 100%;
  height: 100%;
}
.imageorvidep iframe {
  min-height: 350px;
}
.row.mrginNo .col-sm-3 p {
  font-family: Poppins !important;
  font-weight: 500;
  font-size: 18px;
  padding-top: 10px;
}
.row.mrginNo .col-sm-3 .fullImage {
  position: relative;
}

.text {
  white-space: nowrap;
  font-size: 22px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-right: 20px;
}
.row.mrginNo .col-sm-3:hover .fullImage .overlay {
  bottom: 0;
  height: 50px;
  transition: 0.5s ease;
}
.imageOfLogo img {
  width: 70% !important;
}
.mainPageLogo img{
  width: 40% !important;

}
.fullImage .overlay {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background: #ffffffc2;
  bottom: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  /* transition: .5s ease; */
}
.text svg {
  cursor: pointer;
}
.text .edditt svg:hover, .text .descButton svg:hover {
  color: #87562e !important;
}
.text svg:hover {
  color: #f04400;
  transition: all ease-in-out 300ms;
}
.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  z-index: 9999;
}
.col-sm-3.imageorvidep {
  /* padding-bottom: 30px; */
  width: 100%;
}
.fade.EditProfile.modal.show {
  z-index: 9980;
}
.textTablee {
  white-space: nowrap;
  font-size: 22px;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.row.noMArr.fform thead tr {
  border-radius: 10px !important;
}
.row.noMArr.fform tr td {
  border-bottom: 0.56px solid #d5d5d5;
}
.row.noMArr.fform tr th {
  font-weight: 600;
  font-size: 18px;
  border-top: 0.56px solid #d5d5d5;
  border-bottom: 0.56px solid #d5d5d5;
}
.row.noMArr.fform tr th:first-child {
  border-radius: 10px 0 0 10px !important;
}
.row.noMArr.fform td,
.row.noMArr.fform th {
  padding: 20px 15px;
  font-family: Poppins !important;
}
.row.noMArr.fform table {
  width: 100% !important;
  border-collapse: collapse;
}
.col-sm-2.ceenterr {
  text-align: center;
}
.col-sm-12.tributeDetaisl .row {
  margin: 0 0 25px !important;
  font-family: Poppins;
  align-items: flex-end;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 25px 20px;
}
.col-sm-12.tributeDetaisl .col-sm-8 {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 500;
}
.col-sm-12.tributeDetaisl .col-sm-8 p,
.col-sm-12.tributeDetaisl .col-sm-2 p {
  margin-bottom: 0;
  font-family: "Big Caslon FB" !important;
}
.col-sm-12.tributeDetaisl .col-sm-2 {
  margin-bottom: 20px;
  gap: 20px;
  display: flex;
  justify-content: flex-end;
}
.col-sm-2.datee {
  font-size: 22px;
  justify-content: center !important;
  font-weight: 500;
}
.col-sm-12.tributeDetaisl .col-sm-2 svg {
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}
.col-sm-12.tributeDetaisl .col-sm-2 svg:hover {
  color: #87562e !important;
  transition: all ease-in-out 300ms;
}
.col-sm-12.tributeDetaisl .col-sm-12 {
  border-top: 1px solid #676767 !important;
  padding-top: 20px;
}
.container-fluid.profilepAge {
  padding: 0 30px 30px;
}
.profileImage {
  border-radius: 50%;
  width: 180px !important;
  height: 180px !important;
}
.col-sm-12.tributeDetais .row {
  margin: 0 !important;
}
.row.changePass
  span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password,
.container-fluid.profilepAge input {
  height: 45px !important;
  border: 0.56px solid #d5d5d5 !important;
  color: #000 !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  background: transparent !important;
}
.col-sm-12.editBNtn {
  display: flex;
  justify-content: flex-end;
}
.container-fluid.nodata h2{
  color: #fff;
  font-size: 42px;
  font-family: 'Big Caslon FB';
}
.container-fluid.nodata h3{
  color: #fff;
  font-size: 28px;
  font-family: 'Big Caslon FB';
}
.loading{
  height: 88vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row.login .registerBtn{
  background: #87562E !important;
  border: unset !important;
  color: #fff !important;
  /* font-weight: 500; */
  font-size: 22px;
  height: auto !important;
  padding: 10px 40px;
  width: 300px !important;
  border-radius: 10px;
}
.signInDifferent{
  color: #87562E !important;
  background: transparent !important;
  border: unset;
  font-size: 16px;
  height: auto !important;
  text-decoration: underline;
}
.noDataDescription{
  color: #929292 !important;
  font-weight: 600;
  font-family: Poppins !important;
  font-size: 20px;
  margin-bottom: 10px;
}

.container-fluid.nodata.asdasd.fines .col-sm-12{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100% ;
  align-items: center;
  gap: 20px;
}
.container-fluid.nodata.asdasd.fines img {
  width: 300px;
  margin-bottom: 1rem;
}
/* .container-fluid.nodata.asdasd.fines button.linkqr {
  width: 70%;
} */
.col-sm-12.editBNtn button {
  width: auto;
  padding: 10px 15px;
  margin-bottom: 20px;
  height: auto;
  background: #87562e !important;
  border: #87562e !important;
  box-shadow: none !important;
  border-radius: 50% !important;
}
.ant-select.ant-select-outlined.ant-select-in-form-item.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  height: 44px;
  border-radius: 8px;
  border: 0.56px solid #d5d5d5;
  font-family: Poppins !important;
}
.col-sm-4.nipus {
  width: 50%;
}
.row.centers .row.noMarrrgin {
  justify-content: center;
  gap: 5px;
  margin: 0 !important;
}
.row.centers {
  margin: 0 !important;
  text-align: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 100%;
  background: #efefef;
  padding: 5px 0;
}
.col-sm-6.fullWidth {
  width: 100%;
}
.fade.EditProfile.modal.show .modal-dialog {
  max-width: 70%;
}
.col-sm-4.nipus
  .ant-select.ant-select-outlined.ant-select-in-form-item.ant-select-status-success.ant-select-single.ant-select-show-arrow.ant-select-show-search
  .ant-select-selector {
  border: none !important;
  border-radius: 8px !important;
}
.react-datepicker__input-container input {
  height: 44px !important;
  border-radius: 8px !important;
  border: 0.56px solid #d5d5d5;
  font-family: Poppins !important;
  width: 100% !important;
  padding: 0 11px;
}
span.ant-modal-close-x {
  background: #fff;
  border-radius: 50%;
  padding: 8px;
}
span.ant-modal-close-x svg {
  color: #404040;
}
.ant-modal .ant-modal-close {
  top: 20px;
  right: 20px;
}
.ant-modal-header {
  background: transparent !important;
}
.tributeNavigate {
  background: #87562e !important;
}
.col-sm-3.lsistiny img {
  margin-bottom: 20px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}
.ant-modal.imageUploadResizerCover {
  max-width: 70% !important;
  min-width: 69% !important;
  text-align: center;
}
.imageUploadResizer {
  max-width: 50% !important;
  min-width: 49% !important;
  text-align: center;
}
.ant-modal-root .ant-modal-wrap {
  z-index: 9999 !important;
}

.ant-modal.deleteModal {
  min-width: 60%;
}
.ant-modal-title {
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 30px !important;
  padding: 0 0 30px;
  font-weight: 500 !important;
  text-align: center;
}
.ant-modal-content {
  background-image: url("./Assets/deleteModalBg.png") !important;
  background-size: 100% 100% !important;
  padding: 100px 50px !important;
  border-radius: 15px !important;
}
.ant-modal .ant-modal-footer .ant-btn-primary,
.ant-modal .ant-modal-footer .ant-btn-default {
  border: 0.56px solid #d5d5d5 !important;
  background: #fff !important;
  color: #404040 !important;
  width: 25%;
  height: 45px;
  border-radius: 10px;
  font-weight: 500;
  font-family: Poppins !important;
  text-transform: uppercase !important;
  font-family: Poppins;
  font-size: 14px;
}
.modal-header {
  border: none !important;
  padding-bottom: 0 !important;
}
.fade.detailTimelineModal.onboardiingModal.modal.show .modal-dialog {
  max-width: 50% !important;
}
.ant-modal .ant-modal-footer {
  text-align: center;
  background: transparent;
}
.offcannvas p {
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 800 !important;
}
.container-fluid.tributeNavigate {
  margin-bottom: 20px;
  text-align: center;
}
.container-fluid.tributeNavigate .col-sm-12 {
  padding: 10px 12px;
  display: flex;
  gap: 10px;
  cursor: pointer;

  align-items: center;
  justify-content: center;
}
.container-fluid.tributeNavigate p {
  margin-bottom: 0;
  font-family: Poppins !important;
  font-weight: 400;
  color: #fff;
  font-size: 16px;
}
.timeline {
  position: relative;
  max-width: 100%;
}

.timeline::after {
  content: '';
  position: absolute;
  border: 1px dashed #8C8484;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1.5px; /* Adjusted to center the line */
}

.timeline-container {
  position: relative;
  background-color: inherit;
}


.timeline-container::after, .timeline-container.lastElement::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%; /* Center the dots */
  transform: translateX(-50%);
  background-color: #87562E;
  /* border: 4px solid #87562E; */
  /* top: 25px; */
  border-radius: 50%;
  z-index: 1;
}
.row.forImage.timeline-container .col-sm-6 {
  width: calc(50% - 10px);
}
.row.forImage.timeline-container{
  gap: 20px;
}
.timeline-container.lastElement::after{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-color: #87562E;
  border-radius: 50%;
  z-index: 1;
}
.tributeNameWithDate {
  font-family: Poppins !important;
}
.Nameee {
  font-weight: 600;
  margin-bottom: 5px;
}
.nooo {
  margin-bottom: 5px;
}
nav.paginationMain.pagination {
  justify-content: center;
  padding-top: 20px;
}
li.ant-pagination-total-text,
li.ant-pagination-prev,
li.ant-pagination-item.ant-pagination-item-active,
.ant-select.ant-select-outlined.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow.ant-select-show-search,
li.ant-pagination-next {
  font-family: Poppins !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__close-icon::after {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.25) !important;
  font-size: 15px !important;
  width: 20px;
  height: 20px;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  border-radius: 8px !important;
}
.col-sm-12.EditTributeFormImage img {
  max-width: 100%;
  margin-bottom: 20px;
  height: 150px;
}
.swipereBtn {
  background: #87562e !important;
  border: none !important;
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 20px;
  height: auto;
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
}
.profiletribute {
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;
  padding-bottom: 20px;
}
.profileImageBorder {
  width: 100px;
  height: 100px;
}
.profileImageBorder img {
  border: 5px solid #ceab8e;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.onboardingSwpierr .swiper-slide {
  height: 500px;
  overflow: hidden;
  margin-bottom: 30px;
}
.row.noMArr.fform.bioMOdaaal {
  padding-bottom: 40px !important;
}
.myTributess .swiper-slide {
  height: auto !important;
}
.myTributess .swiper-slide .row {
  height: auto !important;
}
.col-sm-12.tributePageListing{
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 10px;
}
.col-sm-12.tributePageListing .myTributeRow{
  flex-grow: 1;
  border-radius: 0 20px 20px 20px !important;
}
.ant-image .ant-image-mask{
  background: transparent !important;
}
.ant-image {
  width: 100%;
}
.ant-image .ant-image-mask .ant-image-mask-info {
  display: none;
}
.col-sm-12.tributePageListing .myTributeRow::after{
  content: "";
  position: absolute;
  top: 0;
  left: 51px;
  border-color: transparent;
  border-style: solid;
  border-width: 12px;
  border-left-width: 12px;
  border-right-width: 0px;
  border-right-color: #87562e;
  border-top-color:#87562e;
}
.myTributeRow {
  border-radius: 20px;
  padding: 20px 30px;
  box-shadow: 0px 6px 10px 4px #dcd5cc;
  margin-bottom: 30px;
}
.swiper-slide .row {
  height: 480px;
  overflow: scroll;
  padding-bottom: 20px;
}
.uploadProfileImage span {
  color: #fff !important;
}
.uploadProfileImage:hover {
  transform: scale(1.01) !important;
}
/* .beforebtns .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select{
  border: 0px solid #000 !important;
} */
.col-sm-12.EditTributeFormImage {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  gap: 30px;
}
.svgSize {
  font-size: 50px;
}
.row.noQRsListed {
  text-align: center;
  width: 100%;
  /* height: 100%; */
}
.col-sm-12.welcome {
  padding: 20px 100px 50px;
}
.col-sm-12.welcome p {
  text-align: left;
  font-size: 30px !important;
  font-weight: 600;
}
ol li p {
  text-align: left;
}
.onBoardd path {
  fill: #87562e !important;
}
.col-sm-12.onboard p {
  font-family: Poppins !important;
  font-size: 16px;
}
.col-sm-12.onboard {
  padding: 20px 150px 0px;
  text-align: center;
}
.col-sm-12.onboard svg {
  margin-bottom: 30px;
}
.onBoardd,
.onBoarddSvg {
  border: none !important;
  background: transparent !important;
  height: auto !important;
  padding: 0 !important;
  font-size: 20px;
}
.qrprevieews {
  padding-top: 30px;
  text-align: center;
}
.col-sm-12.qrprevieews img {
  width: 70%;
}
.qrprevieews button {
  width: 100%;
  /* padding: 15px; */
}
.col-sm-12.whiteBorder.noPaddingtop {
  padding-top: 0 !important;
}
.beforeprof input {
  height: 45px;
  border-radius: 10px;
}
.modal-header h5 {
  margin-bottom: 0;
  font-weight: 600;
  font-family: Poppins !important;
}
.col-sm-12.beforebtns
  button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-action {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  width: auto !important;
}
.slidessTributes .ant-image {
  width: auto !important;
}
/* calender css */

.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  color: #fff !important;
}
.col-sm-12.tributePageListing .myTributeRow {
  width: 100%;
}
.row.hassan .col-sm-1 {
  width: 4%;
}
.hassan .col-sm-11 {
  width: 96%;
}
.myTributeRow .swiper p {
  height: 40px;
  overflow-y: scroll;
}
.hassan{
  width: 100% !important;
}
.react-datepicker {
  width: 100%;
}
.react-datepicker__month-container {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.react-datepicker-popper {
  width: 100%;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3.7rem !important;
}
.react-datepicker__header.react-datepicker__header--custom {
  background-color: #87562e !important;
}
.react-datepicker__header.react-datepicker__header--custom div {
  color: #fff !important;
}
.react-datepicker__month-container select {
  width: 47%;
  height: 37px;
  border-radius: 15px;
  padding: 0 10px;
  margin: 0px 5px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #87562e !important;
}
.ant-picker-dropdown.ant-picker-dropdown-hidden.ant-picker-dropdown-range.ant-picker-dropdown-placement-topLeft {
  z-index: 999999 !important;
  display: none !important;
}

.ant-picker-dropdown.ant-picker-dropdown-range.ant-picker-dropdown-placement-topLeft {
  display: block !important;
  z-index: 999999 !important;
}
p.noDataDescription {
  text-align: center !important;
  font-size: 14px !important;
  width: 84%;
}

@media screen and (max-width: 1550px) {
  .row.noMArr.fform tr th {
    font-size: 15px;
  }
  .row.noMArr.fform tr td,
  .col-sm-12.tributeDetaisl .row .col-sm-12 p {
    font-size: 14px;
  }
  .row.noMArr.fform tr td svg {
    font-size: 20px;
  }
  .row.centers a {
    font-size: 15px !important;
  }
  .col-sm-12.tributeDetaisl .col-sm-8,
  .col-sm-2.datee {
    font-size: 18px;
  }
  .col-sm-12.tributeDetaisl .col-sm-2 svg {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 1440px) {
  .col-sm-3.lsistiny {
    width: 33.3333%;
  }
  .col-sm-12.lisst li a {
    padding-left: 20px;
  }
  .profileImage {
    width: 150px;
    height: 150px;
  }
  .dasshboard p {
    font-size: 26px;
  }
  .qrdetail p {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 1366px) {
  .col-sm-12.lisst {
    padding: 0;
  }
  .col-sm-12.lisst li a {
    font-size: 15px !important;
  }
  .row.centers a {
    font-size: 14px !important;
    padding: 12px 10px;
  }
  .ant-form-item .ant-form-item-label > label,
  .ant-input,
  .ant-select-single,
  span.ant-select-selection-item,
  .react-datepicker__input-container input {
    font-size: 13px;
  }

  .col-sm-12.tributeDetaisl .col-sm-8,
  .col-sm-2.datee {
    font-size: 16px;
  }
  .col-sm-12.tributeDetaisl .row {
    align-items: center;
  }

  .row.noMArr.fform tr td,
  .col-sm-12.tributeDetaisl .row .col-sm-12 p {
    font-size: 13px;
  }
  .row.noMArr.fform td,
  .row.noMArr.fform th {
    padding: 15px 10px;
  }
  .col-sm-12.tributeDetaisl .col-sm-8 {
    gap: 10px;
  }
}

@media screen and (max-width: 1280px) {
  .row.noMArr.fform .ant-picker.ant-picker-outlined,
  .datee .ant-picker.ant-picker-outlined,
  .nipus input,
  .videoLink input,
  .ant-select.ant-select-outlined.ant-select-in-form-item.ant-select-single.ant-select-show-arrow.ant-select-show-search,
  .react-datepicker__input-container input,
  .col-sm-12.submitt button,
  .formRight button,
  .settingBTn button,
  .changePassword button,
  .col-sm-2 button {
    height: 40px !important;
  }
  .col-sm-12.lisst .active {
    padding: 13px 20px;
  }
  .formRight p,
  .uploadHeading p {
    font-size: 20px;
  }
  .subdetail {
    font-size: 16px !important;
  }
  .ant-btn {
    font-size: 13px;
  }
  .row.headeerr .col-sm-12 img.profile {
    width: 40px;
    height: 45px;
  }
  .row.changePass
    span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password,
  .container-fluid.profilepAge input {
    height: 40px !important;
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1024px) {
  .container-fluid.dasshboard.QR {
    padding: 20px 25px 0 !important;
  }
  .container-fluid.qrr {
    padding: 0 25px;
  }
  .row.centers .col-sm-2 {
    width: 18%;
  }
  .row.centers a {
    font-size: 13px !important;
    padding: 12px 5px;
  }
}

@media screen and (max-width: 820px) {
  .row.headeerr.mobile {
    display: flex;
  }
  .col-sm-12.qrprevieews img,
  .QRRadio {
    width: 100%;
    margin: 10px 0;
  }
  .col-sm-6.ddivvs .col-sm-10 p {
    font-size: 12px;
  }
  .col-sm-12.onboard {
    padding: 10px 20px 0px;
  }
  .col-sm-12.welcome {
    padding: 0 20px;
  }
  .fade.detailTimelineModal.onboardiingModal.modal.show .modal-dialog {
    max-width: 70% !important;
  }
  .col-sm-6.ddivvs .col-sm-10 {
    padding-right: 30px;
  }
  .col-sm-6.ddivvs {
    width: 100%;
  }
  .col-sm-6.ddivvs .col-sm-10 h2 {
    font-size: 24px;
  }
  .year p {
    font-size: 16px;
  }
  .dasshboard p {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .year {
    width: 50px;
    height: 50px;
  }
  .container-fluid.dasshboard {
    padding: 20px 30px 10px;
  }
  .offcannvas p {
    font-size: 20px;
    font-weight: 700 !important;
  }
  .container-fluid.modaaals .col-sm-12 {
    padding: 20px;
  }
  .container-fluid.modaaals h2 {
    font-size: 23px;
    margin: 15px 0;
  }
  .detailTimelineModal .modal-dialog,
  .fade.EditProfile.modal.show .modal-dialog {
    max-width: 80% !important;
  }
  .container-fluid.modaaals h1 {
    font-size: 30px;
  }
  .uploaddVideoPhoto .modal-dialog {
    min-width: 80% !important;
  }
  .row.centers a {
    width: 80px;
  }
  .col-sm-12.EditTributeFormImage img {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .col-sm-12.profileImageess {
    width: 90%;
  }
  .profieImagwe img {
    width: 130px;
    height: 130px;
  }
  .profieImagwe {
    width: 140px !important;
    height: 140px;
  }
  .col-sm-12.profileImageess .row.nooMarrgin {
    margin-left: 0 !important;
    margin-top: -60px !important;
  }
  .deadPersonName {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .col-sm-12.topAPd.widthSeventy,
  .col-sm-12.widthSeventy.submitt {
    width: 90%;
  }
  .deadPersonDate {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .col-sm-12.profileImageess .row.nooMarrgin .col-sm-3 {
    width: 150px;
  }
  .row.noMarrrgin .col-sm-2 {
    width: 22% !important;
  }
  .row.noPad .col-sm-3.lsistiny {
    padding: 10px 20px;
    width: 49%;
  }
  .col-sm-2.svgBtn button {
    padding: 0 20px !important;
  }
  .row.headeerr.mobile .col-sm-12 {
    justify-content: space-between;
  }
  .row.headeerr.mobile .col-sm-12 .offcannvas {
    padding-left: 15px;
  }
  /* .offcanvas.offcanvas-start.show {
    padding: 15px 20px;
  } */
  .col-sm-6.formRight p {
    text-align: left;
  }
  .ant-modal-title {
    font-size: 26px !important;
    padding: 0 0 20px;
  }
  .col-sm-12.tributeDetaisl .col-sm-8,
  .col-sm-2.datee {
    font-size: 14px;
  }
  .col-sm-12.tributeDetaisl .col-sm-2 {
    margin-bottom: 10px;
    gap: 10px;
  }
  .col-sm-2.datee {
    width: 22%;
  }
  .col-sm-12.tributeDetaisl .col-sm-8 {
    width: 60%;
  }
  .col-sm-12.tributeDetaisl .row {
    padding: 20px 15px;
  }
  .container-fluid.loginForm {
    width: 75%;
  }
}

@media screen and (max-width: 575px) {
  .dasshboard p {
    font-size: 22px;
  }
  .timeline::after {
    left: 51%;
    margin-left: 1.5px; 
  }
  .timeline-container::after, .timeline-container.lastElement::before,.timeline-container.lastElement::after {
    left: 52%;
  }
.row.forImage.timeline-container .col-sm-6 {
  width: 100%;
  background: #fff;
  z-index: 99;
}
.row.forImage.timeline-container .col-sm-6:hover{
  background-color: #87562E !important;
}
.col-sm-6.ddivvs.timeline-content.mrrightt,.col-sm-6.ddivvs.timeline-content.llefdfft {
  margin: 50px 0;
}
  .updateBtn button {
    width: 100% !important;
  }
  .container-fluid.qrr.timelineeee .col-sm-12.topAPd.widthSeventy{
    padding-bottom: 50px;
  }
  .row.headeerr .col-sm-12 .headerDetaill,
  .row.headeerr.mobile .col-sm-12 .offcannvas {
    gap: 5px;
    padding-right: 20px;
  }
  .row.headeerr .col-sm-12 p {
    font-size: 14px;
  }
  .svgSize {
    font-size: 40px;
  }
  .modal-header h5 {
    font-size: 18px !important;
  }
  .col-sm-12.EditTributeFormImage {
    width: 43% !important;
  }
  .purchaseDetail h4 {
    font-size: 17px;
    line-height: 30px;
  }
  .purchaseDesc p,
  .purchaseDetail p {
    font-size: 12px;
  }
  .ant-btn {
    font-size: 11px;
  }
  .row.login {
    overflow: scroll;
    padding: 40px 0;
  }
  .row.login button {
    height: 45px;
  }
  .brownBgNewLink {
    padding: 0 20px;
    height: 45px;
  }
  .row.noQRsListed p {
    font-size: 14px;
  }
  li.LanguageDropdown
    .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow {
    padding-left: 0;
  }
  li.LanguageDropdown label {
    padding-left: 15px;
    font-size: 15px !important;
  }
  li.LanguageDropdown .ant-col.ant-form-item-label {
    max-width: 40% !important;
  }
  li.LanguageDropdown .ant-col.ant-form-item-control {
    max-width: 60% !important;
  }
  .row.noQRsListed .col-sm-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .col-sm-12.noPad.noDAtata {
    height: 85vh !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .whiteBorder,
  .newPasss {
    padding-top: 30px;
  }
  .row.centers a {
    min-width: 100% !important;
  }
  .container-fluid.loginForm.beforeprof .whiteBorder input,
  .container-fluid.loginForm.beforeprof
    span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password {
    font-size: 14px !important;
    height: 45px !important;
  }
  span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password input {
    height: auto !important;
  }
  .col-sm-12.addPicture {
    padding: 20px 12px;
  }
  .container-fluid.qrr.myTributess.myytributesnon .profiletribute {
    flex-direction: column;
    align-items: flex-start;
  }
  .editandDelete {
    display: flex;
    gap: 10px;
    position: relative;
    right: 0;
    width: 100% !important;
    justify-content: flex-end;
  }
  .row.uploadImageForm.edittrribute {
    padding: 0;
  }
  .col-sm-12.addPicture
    .ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select,
  .col-sm-12.addPicture
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    width: 139px;
  }
  .headerDetaill button.ant-btn.onBoardd svg {
    font-size: 18px !important;
  }
  .addQRBtn {
    padding: 5px !important;
  }
  .swiper-slide .col-sm-12.onboard svg {
    width: 40px !important;
    height: 40px !important;
  }
  .col-sm-12.onboard p,
  ::marker {
    font-size: 13px !important;
  }
  .fade.detailTimelineModal.onboardiingModal.modal.show .modal-dialog {
    max-width: 95% !important;
  }
  .col-sm-12.profileImageess,
  .col-sm-9.prooofDEtttail,
  .col-sm-12.topAPd.widthSeventy,
  .col-sm-12.widthSeventy.submitt,
  .col-sm-3.lsistiny,
  .col-sm-12.widthSeventy {
    width: 100%;
  }
  .row.centers .col-sm-2 {
    width: 24% !important;
    padding: 0 6px;
  }
  .row.centers .col-sm-2 .active,
  .row.centers a {
    font-size: 10px !important;
  }
  .row.centers .row.noMarrrgin {
    justify-content: center;
    gap: 0;
    margin: 0 !important;
  }
  .coverPhoto {
    width: 100%;
    height: 200px;
  }
  .deadPersonName {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .col-sm-6.ddivvs div.notShow {
    text-transform: none !important;
    font-size: 15px;
  }
  .deadPersonDate {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .profieImagwe {
    width: 120px !important;
    height: 120px;
  }
  .profieImagwe img {
    width: 110px;
    height: 110px;
  }
  .col-sm-9.prooofDEtttail div {
    padding-left: 10px;
  }
  .container-fluid.qrr {
    padding: 0 12px;
  }
  .row.centers {
    justify-content: space-between;
  }
  .col-sm-4.nipus {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .fade.detailTimelineModal.modal.show .col-sm-6.formRight.timelineTable {
    width: 100% !important;
    text-align: right;
  }
  .col-sm-6.formRight {
    width: 50%;
  }
  .row.uploadImageForm .row {
    margin: 0 0 10px;
  }
  .col-sm-6.formRight.timelineTable {
    text-align: left;
  }
  .col-sm-12.tributeDetaisl .col-sm-8 {
    width: 100%;
  }
  .col-sm-12.tributeDetaisl .col-sm-2,
  .col-sm-2.svgBtn {
    width: 50% !important;
  }
  .col-sm-12.timelineTable {
    overflow-y: scroll;
    width: 100%;
  }
  .col-sm-6.settingBTn button {
    padding: 0;
    width: 100% !important;
    font-size: 10px;
    margin-bottom: 10px;
  }
  .row.forImage{
    margin: 0;
  }
  .container-fluid.qrr.timelineeee{
    padding-bottom: 20px;
  }
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    width: 100%;
  }
  .ant-modal .ant-modal-footer .ant-btn-primary,
  .ant-modal .ant-modal-footer .ant-btn-default {
    width: 100px;
  }
  .slidessTributes .ant-image {
    width: 100% !important;
  }
  .UserBioMessage {
    font-size: 15px;
    padding: 10px 13.5px 0;
    margin-bottom: 0.5rem;
  }
  p.tributerName{
    margin-bottom: 0;
  }
  .httml{
    padding: 0 13.5px !important;
  }
  .leterCircle {
    width: 43px !important;
  }
  .imageUploadResizer,
  .ant-modal.imageUploadResizerCover {
    max-width: 95% !important;
  }
  .container-fluid.tributeNavigate p {
    font-size: 14px;
  }
  .mymodelss canvas {
    width: 100% !important;
  }
  .offcannvas p {
    font-size: 18px;
  }
  .profileImageBorder {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row.noMArr.fform.tribute .col-sm-12.widthSeventy .row.inTribute {
    margin: 0;
  }
  .profiletribute {
    gap: 5px;
  }
  .profileImageBorder img,
  .black {
    width: 70px;
    height: 70px;
    border: 3px solid #ceab8e;
  }
  .row.noPad .col-sm-3.lsistiny {
    margin: 0;
  }
  .col-sm-12.myTributeRow p {
    font-size: 13px;
  }
  .row.noPad.qrListingNew {
    padding: 0 0 30px;
  }
  .ant-modal-title {
    font-size: 18px !important;
    padding: 0 0 20px;
  }
  .sidebarQRSListing P {
    font-weight: 500;
    font-size: 15px;
  }
  .react-datepicker__month-container select {
    width: 45%;
  }
  .col-sm-6.ddivvs .col-sm-10 p {
    font-size: 13px;
  }
  .row.uploadImageForm {
    padding: 10px 0 20px;
  }
  .col-sm-12.imageLimit p {
    padding-top: 20px;
    font-size: 15px;
  }
  .topAPd {
    padding: 30px 15px 10px;
  }
  .row.headeerr.mobile .col-sm-12 .offcannvas {
    padding-left: 0 !important;
  }
  .row.noPad .col-sm-3.lsistiny {
    width: 100% !important;
    margin-top: 20px !important;
  }
  .col-sm-12.profileImageess .row.nooMarrgin .col-sm-3 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .col-sm-6.ddivvs .col-sm-10 {
    padding-right: 12px;
  }
  .detailTimelineModal .modal-dialog,
  .fade.EditProfile.modal.show .modal-dialog {
    max-width: 95% !important;
  }
  .container-fluid.loginForm {
    width: 90%;
  }
  .row.headeerr .col-sm-12 .headerDetaill {
    padding-right: 0;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 1.7rem !important;
  }
  .editBTn {
    height: 30px;
    width: 30px;
  }
  .editBTn img {
    width: 15px;
    height: auto !important;
  }
  .col-sm-12.logoImage {
    padding: 0px 30px 0 12px;
  }
  .sidebarQRSListing {
    padding-left: 12px;
  }
  img.ant-image-preview-img {
    width: 90%;
  }
  .col-sm-6.ddivvs .col-sm-10 h2 {
    font-size: 20px;
  }
  .row.hassan .col-sm-1 {
    position: absolute;
  }
  .hassan .col-sm-11 {
    width: 85%;
  }
  .row.hassan .col-sm-1 {
    top: 0;
    left: 0;
  }
  .row.hassan {
    justify-content: flex-end;
  }
  .col-sm-6.settingBTn {
    width: 100%;
  }
  .col-sm-12.tributePageListing {
    justify-content: center;
  }
  .editandDelete button {
    height: 30px;
    width: 30px;
  }
  .editandDelete svg {
    font-size: 15px !important;
  }
  .editandDelete button img {
    width: 15px !important;
    height: 15px !important;
  }
  .row.hassan p {
    font-size: 14px;
  }
  .myTributeRow {
    padding: 20px 10px !important;
  }
  .hassan .col-sm-11 {
    padding: 0 !important;
  }
  .row.inTribute {
    padding: 0 !important;
  }
  .col-sm-6.formRight.timelineTable {
    width: 100%;
  }
  .col-sm-6.formRight.timelineTable button{
    width: 100%;
  }
  .col-sm-12.tributePageListing .myTributeRow {
    border-radius: 20px 20px 20px 20px !important;
  }
  .ant-modal-content {
    padding: 100px 10px !important;
  }
  .col-sm-12.topAPd {
    padding: 0;
  }
  .modal-body {
    padding: 10px;
  }
  .swiper-slide .row {
    height: 300px !important;
  }
  .onboardingSwpierr .swiper-slide {
    height: 360px !important;
  }
}
body.modal-open {
  overflow: auto ! IMPORTANT;
  overflow-x: hidden !important;
}
body {
  overflow: auto ! IMPORTANT;
  overflow-x: hidden !important;
}
